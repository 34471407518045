import { getField, updateField } from 'vuex-map-fields';
import isEmpty from 'lodash.isempty';
import mergeWith from 'lodash.mergewith';

import * as requestsAPI from '@/api/requests.api';
import * as editPolicyAPI from '@/api/edit-policy.api';
import * as entityAPI from '@/api/entity.api';
import { abortController } from '@/helpers/api';
import { setUrlParams } from '@/helpers/general';
import { ErrorHandler } from '@/helpers';
import { formatDate } from '@/helpers/date';
import {
  SpecializedStatuses,
  UserRequestStatuses,
  EstablishmentType,
  mediaDocTypes,
  mediaAttachableTypes,
  RequestStatuses,
  EditRequestUnitActions,
  EditRequestUnitTypes,
  EntityTypes,
} from '@/constants/enums';
import i18n from '@/i18n';
import {
  listContributionSources,
  normalizeEntity,
  normalizeBranch,
} from '@/services/entity.service';
import { normalizeEntityLocations, normalizeRequests } from '@/services/requests.service';
import { getDocumentsList, uploadDocuments } from '@/services/media.service';
import { createEditRequest, fetchEditItems } from '@/services/edit-request.service';
import { submitEditPolicyRequest, filterNullValuesFromObj } from '@/services/edit-policy.service';
import { acceptRequestBySpecialized, rejectRequestBySpecialized } from '@/api/family-fund.api';
import { createOrUpdateEntityLocations } from '@/api/entity.api';

const handleRequests = (data) => data.map((r) => ({
  ...r,
  createdAt: formatDate(r.createdAt),
  establishmentType: r.unit?.type,
  unitNameArabic: r.unit?.nameArabic,
  unitNameEnglish: r.unit?.nameEnglish,
  remainingValue: r.remainingData?.remainingValue,
  remainingType: r.remainingData?.remainingType,
  pausedAt: r.remainingData?.pausedAt,
  updatedAt: r.updatedAt ? formatDate(r.updatedAt) : formatDate(r.createdAt),
}));

const handleEditPolicyRequests = (data) => data.map((r) => ({
  ...r,
  createdAt: formatDate(r.createdAt),
  establishmentType: r.unit?.type,
  unitNameArabic: r.unit?.nameArabic,
  unitNameEnglish: r.unit?.nameEnglish,
  remainingValue: r?.remainingValue,
  remainingType: r?.remainingType,
  updatedAt: r.updatedAt ? formatDate(r.updatedAt) : formatDate(r.createdAt),
}));
const findEditItemByUnitType = (uType, list) => {
  if (!Array.isArray(list)) return [];
  return list.find(({ unitType }) => unitType === EditRequestUnitTypes[uType]);
};

const listProperties = (propertyName, items) => {
  if (Array.isArray(items)) {
    return items.map((item) => ({
      [propertyName]: item[propertyName],
    }));
  }
  return [];
};

const abortControllerName = 'Abort Request';
const abortControllerRequests = 'Abort Request List';
const cancelSource = abortController();
const cancelSourceEditRequests = abortController();
const filterPattern = () => ({
  name: '',
  id: '',
});

function handleFilterOptions(options) {
  return {
    id: options.id,
    entityName: options.entityName,
  };
}

function handleFilterOptionsEntities(options) {
  return {
    entityDate: options.entityDate,
    entityNameOrId: options.entityNameOrId,
    entityType: options.entityType,
    remainingTime: options.remainingTime,
  };
}

function makeEntityLocationsPayload(entityLocations) {
  return entityLocations.map((entityLocation) => ({
    cityUids: entityLocation.selectedCities,
    regionCode: entityLocation.regionCode,
    type: 'activity',
  }));
}

const state = {
  filterOptions: {},
  requests: [],
  loading: false,
  searchFilter: filterPattern(),
  employeeRequestsLoading: false,
  editPolicyReqeustsLoading: false,
  membershipLoading: false,
  sevenHundredErrorsLoading: false,
  disclosureFormLoading: false,
  errors: [],
  errorsFilter: {
    unitId: null,
    code: null,
    error: null,
  },
  requestDetails: {},
  checkMinistryOfInteriorRequestDetails: false,
  acknowledgeDecision: false,
  sevenHundredErrors: [],
  memberships: [],
  disclosureFormAnswers: {},
  membershipConditions: [],
  fileLoading: false,
  activeMemberStatus: SpecializedStatuses.Pending,
  activeRequestType: EstablishmentType.Entity,
  entityRequestsCount: 0,
  branchRequestsCount: 0,
  totalRequestsCount: 0,
  entityLocations: [],
  adminRecommendation: null,
  editRequestId: null,
  entityEditItem: null,
  entityLocationEditItems: [],
  originalEntityLocationEditItems: [],
  entityLocationEditItemsOptions: {},
  editItemsHasBeenModified: false,
  entityEditItemHasBeenModified: false,
  entityLocationEditItemHasBeenModified: false,
  currentRequestMode: {
    requestId: Number(),
    isPassed: Boolean(),
  },
};

const getters = {
  getField,
  requests: (store) => store.requests,
  isRequestEmpty: (store) => isEmpty(store.requests),
  isLoading: (store) => store.loading,
  getFilterSettings: (store) => store.searchFilter,
  isCurrentRequestInHistoryMode: (store) => store.currentRequestMode?.isPassed ?? false,
  isEmployeeRequestsLoading: (store) => store.employeeRequestsLoading,
  isEditPolicyRequestsLoading: (store) => store.editPolicyReqeustsLoading,
  isFileLoading: (store) => store.fileLoading,
  isMembershipLoading: (store) => store.membershipLoading,
  isSevenHundredErrorsLoading: (store) => store.sevenHundredErrorsLoading,
  getSevenHundredErrorsFilter: (store) => store.errorsFilter,
  getRequestDetails: (store) => store.requestDetails,
  getCheckMinistryOfInteriorRequestDetails: (store) => store.checkMinistryOfInteriorRequestDetails,
  getAcknowledgeDecision: (store) => store.acknowledgeDecision,
  getMemberships: (store) => store.memberships,
  getSevenHundredErrors: (store) => store.sevenHundredErrors,
  getDisclosureFormAnswers: (store) => store.disclosureFormAnswers,
  getMembershipConditions: (store) => store.membershipConditions,
  activeMemberStatus: (store) => store.activeMemberStatus,
  acceptedMembers: (store) => store.memberships.filter(
    (member) => member.status === UserRequestStatuses.AcceptedBySpecialized,
  ),
  rejectedMembers: (store) => store.memberships.filter(
    (member) => member.status === UserRequestStatuses.RejectedBySpecialized,
  ),
  pendingMembers: (store) => store.memberships.filter(
    (member) => member.status === UserRequestStatuses.AcceptedByUser,
  ),
  activeRequestType: (store) => store.activeRequestType,
  isBranchesRequest: (store) => store.activeRequestType === EstablishmentType.Branch,
  getEntityRequestsCount: (store) => store.entityRequestsCount,
  getBranchRequestsCount: (store) => store.branchRequestsCount,
  getTotalRequestsCount: (store) => store.totalRequestsCount,
  getEntityLocations: (store) => store.entityLocations,
  getAdminRecommendationInfo: (store) => store.adminRecommendation,
  getEditRequestId: (store) => store.editRequestId,
  getEntityEditItem: (store) => store.entityEditItem,
  hasSupervisoryAdjustments: (store) => store.editItemsHasBeenModified
    || store.entityEditItemHasBeenModified
    || store.entityLocationEditItemHasBeenModified,
  isPrivateAssociation: (store) => {
    const entityType = store.requestDetails.type || store.requestDetails.parentType;

    return entityType === EntityTypes.PrivateAssociation;
  },
  isPrivateCorporation: (store) => {
    const entityType = store.requestDetails.type || store.requestDetails.parentType;

    return entityType === EntityTypes.PrivateCorporation;
  },
  getOriginalEntityLocationEditItems: (store) => store.originalEntityLocationEditItems,
  getEntityLocationEditItems: (store) => store.entityLocationEditItems,
  getEntityLocationEditItemHasBeenModified: (store) => store.entityLocationEditItemHasBeenModified,
};

const mutations = {
  updateField,
  resetCounts: (store) => {
    store.entityRequestsCount = 0;
    store.branchRequestsCount = 0;
    store.totalRequestsCount = 0;
  },
  setLoading: (store, value) => {
    store.loading = value;
  },
  setCurrentRequestMode: (store, { requestId, isPassed }) => {
    store.currentRequestMode.requestId = Number(requestId);
    store.currentRequestMode.isPassed = Boolean(isPassed);
  },
  setMembershipLoading: (store, value) => {
    store.membershipLoading = value;
  },
  setSevenHundredErrorsLoading: (store, value) => {
    store.sevenHundredErrorsLoading = value;
  },
  setEditPolicyRequestsLoading: (store, value) => {
    store.editPolicyReqeustsLoading = value;
  },
  setDisclosureFormLoading: (store, value) => {
    store.disclosureFormLoading = value;
  },
  setRequests: (store, requests) => {
    store.requests = requests;
  },
  setMemberships: (store, members) => {
    store.memberships = members;
  },
  setSevenHundredErrors: (store, sevenHundredErrors) => {
    store.sevenHundredErrors = sevenHundredErrors;
  },
  setDisclosureFormAnswers: (store, answers) => {
    store.disclosureFormAnswers = answers;
  },
  setStatus: (store, { requestId, status }) => {
    const member = store.memberships.find((member) => member.id === requestId);
    member.status = status;
  },
  setActiveMemberStatus: (store, status) => {
    store.activeMemberStatus = status;
  },
  setRequestDetails: (store, entity) => {
    store.requestDetails = entity;
  },
  setCheckMinistryOfInteriorRequestDetails: (store, checkMinistryOfInteriorRequestDetails) => {
    store.checkMinistryOfInteriorRequestDetails = checkMinistryOfInteriorRequestDetails;
  },
  setAcknowledgeDecision: (store, decision) => {
    store.acknowledgeDecision = decision;
  },
  setVisibleInfo: (store, id) => {
    store.memberships = store.memberships.map((m) => ({
      ...m,
      showInfo: m.id === id ? !m.showInfo : false,
    }));
  },
  setFileUploading: (store, value) => {
    store.fileLoading = value;
  },
  setActiveRequestType: (store, requestType) => {
    store.activeRequestType = requestType;
    setUrlParams({ requestType });
  },
  resetStoreToDefaultState: (store) => {
    store.activeRequestType = EstablishmentType.Entity;
  },
  setTotalRequestsCount: (store, value) => {
    store.totalRequestsCount = value;
  },
  setMembershipConditions: (store, data) => {
    store.membershipConditions = data;
  },
  setEntityLocations: (store, list) => {
    store.entityLocations = list;
  },
  setAdminRecommendation: (store, data) => {
    store.adminRecommendation = data;
  },
  setEditRequestId: (store, id) => {
    store.editRequestId = Number(id);
  },
  setEntityEditItem: (store, item) => {
    store.entityEditItem = item;
  },
  setEntityLocationEditItems: (store, entityLocations) => {
    store.entityLocationEditItems = normalizeEntityLocations(Object.values(entityLocations));
  },
  setOriginalEntityLocationEditItems: (store, entityLocations) => {
    store.originalEntityLocationEditItems = normalizeEntityLocations(
      Object.values(entityLocations),
    );
  },
  mergeEditItemWithRequestDetails: (store, editItem) => {
    store.requestDetails = { ...store.requestDetails, ...editItem };
  },
  subcribeOnEntityEditItemModification: (store, boolean) => {
    store.entityEditItemHasBeenModified = boolean;
  },
  subcribeOnEntityLocationEditItemModification: (store, boolean) => {
    store.entityLocationEditItemHasBeenModified = boolean;
  },
  resetModificationEditItemsState: (store, boolean) => {
    state.editItemsHasBeenModified = boolean;
  },
  checkIfEditItemsWasModified: (store, items) => {
    store.editItemsHasBeenModified = items.some((item) => {
      if (!item.newPayload) return false;
      return Object.values(item.newPayload).some((v) => {
        if (Array.isArray(v)) return v.length;
        return v;
      });
    });
  },
  setFilterOptions: (state, filterOptions) => {
    state.filterOptions = { ...handleFilterOptions(filterOptions) };
  },
  setFilterOptionsEntities: (state, filterOptions) => {
    state.filterOptions = { ...handleFilterOptionsEntities(filterOptions) };
  },
  resetFilterOptions: (state) => {
    state.filterOptions = {
      ...handleFilterOptions({
        id: null,
        entityName: null,
      }),
    };
  },
  resetSevenHundredErrorsFilter: (state) => {
    state.errorsFilter = {
      unitId: null,
      code: null,
      error: null,
    };
  },
  resetFilterOptionsEntities: (state) => {
    state.filterOptions = {
      ...handleFilterOptionsEntities({
        entityDate: null,
        entityNameOrId: null,
        entityType: null,
        remainingTime: null,
      }),
    };
  },
  setEntityLocationEditItemsOptions: (state, options) => {
    state.entityLocationEditItemsOptions = options;
  },
};
const actions = {
  cancelRequest: () => {
    if (cancelSource.hasItems()) {
      cancelSource.abort(abortControllerRequests);
    }
  },
  checkEstablishmentRequestIfPassed: async ({ commit, getters }, requestId) => {
    let currentRequest = getters.requests.find((request) => +request.id === +requestId);
    if (!currentRequest) {
      try {
        const res = await entityAPI.findEstablishmentAdminRequestStatus(requestId);
        currentRequest = res?.data?.findEstablishmentAdminRequest;
      } catch (e) {
        currentRequest = ErrorHandler.parseFetchErrors(e);
      }
    }
    commit('setCurrentRequestMode', {
      requestId: +requestId,
      isPassed: [
        RequestStatuses.Accepted,
        RequestStatuses.Rejected,
        RequestStatuses.Canceled,
      ].includes(currentRequest?.status),
    });
    return currentRequest;
  },

  getMinistryOfInteriorFile: async ({ commit }, { id }) => {
    commit('setFileUploading', true);
    const res = await getDocumentsList(
      id,
      mediaAttachableTypes.Entity,
      mediaDocTypes.MinistryOfInteriorAdminFile,
      null,
    );
    commit('setFileUploading', false);
    return res;
  },
  getSpecializedFile: async ({ commit }, { id }) => {
    commit('setFileUploading', true);
    const res = await getDocumentsList(
      id,
      mediaAttachableTypes.Entity,
      mediaDocTypes.SpecializedAdminFile,
      null,
    );
    commit('setFileUploading', false);
    return res;
  },
  specializedFileUpload: async ({ commit }, { files, id }) => {
    commit('setFileUploading', true);
    const res = await uploadDocuments(
      files,
      id,
      mediaAttachableTypes.Entity,
      mediaDocTypes.SpecializedAdminFile,
    );
    commit('setFileUploading', false);
    return res;
  },
  ministryOfInteriorFileUpload: async ({ commit }, { files, id }) => {
    commit('setFileUploading', true);
    const res = await uploadDocuments(
      files,
      id,
      mediaAttachableTypes.Entity,
      mediaDocTypes.MinistryOfInteriorAdminFile,
    );
    commit('setFileUploading', false);
    return res;
  },
  sendDecision: async ({ dispatch }, data) => {
    try {
      let res;
      if (data.status === RequestStatuses.Accepted) {
        res = await requestsAPI.acceptEstablismentRequest(data.requestId);
      } else {
        res = await requestsAPI.rejectEstablishmentRequest(data.requestId, data.statusReason);
      }
      dispatch(
        'toast/showNotification',
        {
          message: i18n.t('general.requestHasBeenSent'),
          duration: 4000,
          type: 'success',
        },
        { root: true },
      );
      return res;
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    }
  },
  sendMemberRequestStatus: async ({ commit }, { requestId, status }) => {
    try {
      const method = status === 'accept' ? acceptRequestBySpecialized : rejectRequestBySpecialized;
      const res = await method(requestId);
      const data = status === 'accept'
        ? res.data?.acceptBySpecializedUserRequest
        : res.data?.rejectBySpecializedUserRequest;
      commit('setStatus', { requestId, status: data?.status });
      return data;
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    }
  },
  fetchSevenHundredErrors: async ({ commit, rootGetters, getters }, { unitId }) => {
    let sevenHundredErrors = [];
    commit('setSevenHundredErrorsLoading', true);
    try {
      const filterBy = getters.getSevenHundredErrorsFilter;
      console.log(filterBy);
      const res = await requestsAPI.fetchSevenHundredErrorsRequests({
        unitId,
        page: rootGetters['sevenHundredErrorsPagination/selectedPage'],
        size: rootGetters['sevenHundredErrorsPagination/selectedSize'],
        ...filterBy,
      });
      console.log(res.data.listUnifiedNumberErrors.errors);
      const metaData = res.data?.listUnifiedNumberErrors?.meta;
      const list = res.data.listUnifiedNumberErrors.errors || [];
      sevenHundredErrors = list;
      commit('setSevenHundredErrors', sevenHundredErrors);
      commit('sevenHundredErrorsPagination/setTotalPages', metaData?.pageCount ?? 2, { root: true });
      commit('sevenHundredErrorsPagination/setTotalRecords', metaData?.recordsCount ?? 10, { root: true });
    } catch (e) {
      console.warn(e);
      sevenHundredErrors = { error: e };
    } finally {
      commit('setSevenHundredErrorsLoading', false);
    }
    return sevenHundredErrors;
  },
  fetchMemberships: async ({ commit }, { entityId, unitType }) => {
    let memberships = null;
    commit('setMembershipLoading', true);
    try {
      const res = await requestsAPI.fetchUserRequests(entityId, unitType);
      const list = res.data.userRequests.list || [];
      memberships = list.map((member) => normalizeRequests(member));
      commit('setMemberships', memberships);
    } catch (e) {
      console.warn(e);
      memberships = { error: e };
    } finally {
      commit('setMembershipLoading', false);
    }
    return memberships;
  },
  fetchDisclosureForm: async ({ commit }, requestId) => {
    let list = null;
    commit('setDisclosureFormLoading', true);
    try {
      const res = await requestsAPI.fetchDisclosureFormAnswers(requestId);
      list = res.data.findUserRequest.disclosureForm || {};
      commit('setDisclosureFormAnswers', list);
    } catch (e) {
      console.warn(e);
      list = { error: e };
    } finally {
      commit('setDisclosureFormLoading', false);
    }
    return list;
  },
  getOwnerUserRequest: async (_, entityId) => {
    try {
      const res = await entityAPI.findOwnerAsMemberUserRequestByEntityId(entityId);
      return res?.data?.findOwnerAsAMemberUserRequestByEntityId;
    } catch (e) {
      console.warn(e);
      return {};
    }
  },
  fetchRequestDetails: async ({ commit, dispatch }, entityId) => {
    commit('setLoading', true);
    try {
      const resp = await entityAPI.findEntity(entityId);
      const entity = resp?.data?.findEntityById || {};
      const contributionSources = await listContributionSources(entityId);
      const ownerMembership = await dispatch('getOwnerUserRequest', entityId);
      commit(
        'setRequestDetails',
        normalizeEntity({
          ...entity,
          contributionSources,
          ownerMembership,
        }),
      );
      await dispatch(
        'lookup/fetchActivities',
        { classificationId: entity.classificationDetails?.secondSubClassificationId },
        { root: true },
      );
      await dispatch(
        'lookup/fetchGoals',
        {
          classificationId: entity.classificationDetails?.secondSubClassificationId,
          type: entity.type,
        },
        { root: true },
      );
      await dispatch('requests/fetchCheckMinistryOfInteriorRequestDetails', entityId, { root: true });
      return entity;
    } catch (e) {
      console.warn(e);
      return { error: e };
    } finally {
      commit('setLoading', false);
    }
  },
  fetchCheckMinistryOfInteriorRequestDetails: async ({ commit }, entityId) => {
    const res = await entityAPI.fetchCheckMinistryOfInteriorRequest(entityId);
    const request = res?.data?.checkMinistryOfInteriorRequest;
    commit('setCheckMinistryOfInteriorRequestDetails', request?.exists);
    return request;
  },
  getRequestsList: async (
    { commit, state, rootGetters },
    {
      namespace, status, active, activeMode,
    },
  ) => {
    let result;
    commit('setLoading', true);
    if (cancelSource.hasItems()) {
      cancelSource.abort(abortControllerRequests);
    }
    const cancelToken = cancelSource.register();
    try {
      const {
        entityDate, entityNameOrId, entityType, remainingTime,
      } = state.filterOptions;
      const payload = {
        establishmentType: state.activeRequestType,
        status,
        active,
        adminRole: rootGetters['auth/adminRole'],
        activeMode,
        page: rootGetters[`${namespace}/selectedPage`],
        size: rootGetters[`${namespace}/selectedSize`],
        createdAt: entityDate,
        unitTitle: entityNameOrId,
        remainingTime,
        type: entityType,
      };
      console.log(state.filterOptions);
      const res = await requestsAPI.fetchEstablishmentRequests(payload, { cancelToken });
      const metaData = res?.data?.establishmentAdminRequests.meta;
      const data = res?.data?.establishmentAdminRequests?.establishmentAdminRequests;
      if (metaData) {
        commit(`${namespace}/setTotalPages`, metaData.pageCount, { root: true });
        commit(`${namespace}/setTotalRecords`, metaData.recordsCount, { root: true });
        commit('setTotalRequestsCount', metaData.recordsCount);
      }
      commit('setRequests', handleRequests(data));
      result = { ...res };
      commit('setLoading', false);
    } catch (e) {
      if (e.message !== abortControllerRequests) commit('setLoading', false);
      result = ErrorHandler.parseFetchErrors(e);
      commit('setRequests', []);
    }
    return result;
  },

  getEditPolicyRequests: async ({ commit, rootGetters }, { namespace, requestType, status }) => {
    if (cancelSourceEditRequests.hasItems()) {
      cancelSourceEditRequests.abort(abortControllerName);
    }
    commit('setEditPolicyRequestsLoading', true);
    const cancelToken = cancelSourceEditRequests.register();
    try {
      const { id, entityName } = state.filterOptions;
      const res = await editPolicyAPI.fetchEditPolicyRequestsV2(
        {
          requestType,
          status,
          id,
          entityName,
          page: rootGetters[`${namespace}/selectedPage`],
          size: rootGetters[`${namespace}/selectedSize`],
        },
        { cancelToken },
      );
      const metaData = res.data.listEditRequests.meta;
      const requests = res.data.listEditRequests.editRequests;
      if (metaData) {
        commit(`${namespace}/setTotalPages`, metaData.pageCount, { root: true });
        commit(`${namespace}/setTotalRecords`, metaData.recordsCount, { root: true });
        commit('setTotalRequestsCount', metaData.recordsCount);
      } else {
        commit(`${namespace}/setTotalPages`, 1, { root: true });
        commit(`${namespace}/setTotalRecords`, 0, { root: true });
        commit('setTotalRequestsCount', 0);
      }

      commit('setRequests', handleEditPolicyRequests(requests));
      commit('setEditPolicyRequestsLoading', false);
    } catch (e) {
      console.warn(e);
      if (e.message !== abortControllerName) commit('setEditPolicyRequestsLoading', false);
      commit('setRequests', []);
    }
  },
  updateRequestDetails: async (_, {
    id, nameArabic, nameEnglish, goalIds, activityIds,
  }) => {
    try {
      const resp = await entityAPI.updateEntityBySupervisoryAdmin({
        id,
        nameArabic,
        nameEnglish,
        goalIds,
        activityIds,
      });
      return resp;
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    }
  },
  fetchMembershipConditions: async ({ commit }, id) => {
    try {
      const { data } = await requestsAPI.fetchMembershipConditionsByEntityId(id);
      commit('setMembershipConditions', data.membershipConditions.membershipConditions);
      return data.membershipConditions.membershipConditions;
    } catch (e) {
      return ErrorHandler.parseFetchErrors(e);
    }
  },
  fetchEntityLocations: async ({ commit }, id) => {
    let entityLocations;
    try {
      const { data } = await entityAPI.fetchEntityLocationsByEntityId({ id });
      entityLocations = data.entityLocations.entityLocations;
      commit('setEntityLocations', normalizeEntityLocations(data.entityLocations.entityLocations));
    } catch (e) {
      entityLocations = {
        error: e,
      };
    }
    return entityLocations;
  },
  fetchAdminRecommendation: async ({ commit }, { entityId, adminRole, establishmentType }) => {
    let adminRecommendation = null;
    try {
      const res = await requestsAPI.fetchAdminRecommendation({
        unitId: entityId,
        adminRole,
        establishmentType,
      });
      adminRecommendation = res?.data?.establishmentAdminRequests?.establishmentAdminRequests;
      commit('setAdminRecommendation', adminRecommendation);
    } catch (e) {
      adminRecommendation = {
        error: e,
      };
    }
    return adminRecommendation;
  },
  findLastRejectedEstablishmentAdminRequest: async (_, { id, type }) => {
    try {
      const res = await requestsAPI.findLastRejectedEstablishmentAdminRequest(id, type);
      return res?.data?.findLastRejectedEstablishmentAdminRequest;
    } catch (e) {
      return ErrorHandler.parseFetchErrors(e);
    }
  },
  createEditRequest: async ({ commit }, { entityId, unitType, editType }) => {
    try {
      const res = await createEditRequest(entityId, unitType, editType);
      commit('setEditRequestId', res?.id);
    } catch (e) {
      throw ErrorHandler.parseFormErrors(e);
    }
  },
  fetchEditItems: async ({ commit }, editRequestId) => {
    try {
      const res = await fetchEditItems(editRequestId);
      const entityEditItem = findEditItemByUnitType(EditRequestUnitTypes.Entity, res);
      const entityLocationEditItems = findEditItemByUnitType(
        EditRequestUnitTypes.EntityLocation,
        res,
      );
      commit(
        'mergeEditItemWithRequestDetails',
        mergeWith(
          filterNullValuesFromObj(entityEditItem?.currentPayload),
          filterNullValuesFromObj(entityEditItem?.newPayload),
          (firstObjValue, secondObjValue) => {
            if (Array.isArray(secondObjValue) && secondObjValue.length === 0) {
              return firstObjValue;
            }
            return secondObjValue;
          },
        ),
      );
      commit('setEntityEditItem', entityEditItem);
      const entityLocations = mergeWith(
        filterNullValuesFromObj(entityLocationEditItems.currentPayload.entityLocations),
        filterNullValuesFromObj(
          entityLocationEditItems.newPayload.entityLocations,
          (firstObjValue, secondObjValue) => {
            if (Array.isArray(secondObjValue) && secondObjValue.length === 0) {
              return firstObjValue;
            }
            return secondObjValue;
          },
        ),
      );
      commit('setEntityLocationEditItems', entityLocations);
      commit('setOriginalEntityLocationEditItems', entityLocations);
      commit('setEntityLocationEditItemsOptions', {
        id: entityLocationEditItems.id,
        unitId: entityLocationEditItems.unitId,
        action: EditRequestUnitActions.Update,
      });
      commit('checkIfEditItemsWasModified', listProperties('newPayload', [entityEditItem]));
    } catch (e) {
      throw ErrorHandler.parseFormErrors(e);
    }
  },
  updateEditItem: async ({ commit }, { editItemId, unitId, payload }) => {
    let result;
    try {
      result = await editPolicyAPI.updateEditItem(
        editItemId,
        unitId,
        EditRequestUnitActions.Update,
        { ...payload },
      );
      commit('subcribeOnEntityEditItemModification', true);
    } catch (e) {
      result = ErrorHandler.parseFormErrors(e);
    }
    return result;
  },
  submitEditRequest: async ({ getters }) => {
    try {
      return await submitEditPolicyRequest(getters.getEditRequestId);
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    }
  },
  findBranchDetails: async ({ commit }, id) => {
    try {
      const res = await entityAPI.findBranchById(id);
      const branch = normalizeBranch(res.data.findBranchById);
      commit('setRequestDetails', branch);
      return branch;
    } catch (e) {
      return ErrorHandler.parseFetchErrors(e);
    }
  },
  createOrUpdateEntityLocations: async ({ state }) => {
    try {
      if (!state.entityLocations?.length) return [];
      const res = await createOrUpdateEntityLocations(
        state.requestDetails.id,
        makeEntityLocationsPayload(state.entityLocations),
      );
      return res;
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    }
  },
  updateEntityLocationEditItems: async ({ state }) => {
    const { id, unitId, action } = state.entityLocationEditItemsOptions;
    const { entityLocationEditItems } = state;
    try {
      return await editPolicyAPI.updateEditItem(id, unitId, action, {
        entityLocations: makeEntityLocationsPayload(entityLocationEditItems),
      });
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
