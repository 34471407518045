export const DialogTypes = {
  Confirm: 'Confirm',
  UnifiedNationalNumber: 'UnifiedNationalNumber',
};

export const AdminRoles = {
  Supervisory: 'supervisory',
  Specialized: 'specialized',
  SuperAdmin: 'superadmin',
  HrsdAdmin: 'hrsd_admin',
  HrsdEmployee: 'hrsd_employee',
  RegistrationAndPermitAdmin: 'registration_and_permit_admin',
  RegistrationAndPermitEmployee: 'registration_and_permit_employee',
  RegistrationAndPermitManager: 'registration_and_permit_manager',
  MinistryOfInteriorEmployee: 'ministry_of_interior_employee',
  RegistrationAndPermitCeo: 'registration_and_permit_ceo',
  GovernanceAdmin: 'governance_admin',
  GovernanceHrsdEmployee: 'governance_hrsd_employee',
  GovernanceCenterEmployee: 'governance_center_employee',
  CenterFinancialSafetyEmployee: 'center_financial_safety_employee',
  CharteredAccountantOfficeAdmin: 'chartered_accountant_office_admin',
  AuditOfficer: 'audit_officer',
  BeneficiaryAdmin: 'beneficiary_admin',
};

export const UserRoles = {
  Individual: 'individual',
  Delegate: 'delegate',
};

export const UserRequestStatuses = {
  Draft: 'draft',
  Pending: 'pending',
  AcceptedByUser: 'accepted_by_user',
  RejectedByUser: 'rejected_by_user',
  AcceptedBySpecialized: 'accepted_by_specialized_admin',
  RejectedBySpecialized: 'rejected_by_specialized_admin',
  FinalReject: 'final_rejected',
};

export const UnifiedNumberRequestStatuses = {
  Draft: 'Draft',
  Pending: 'Pending',
  Rejected: 'Rejected',
  Accepted: 'Accepted',
};

export const DocumentItemType = {
  User: 'user',
  Employee: 'employee_request',
  Entity: 'entity',
};

export const DocumentType = {
  SecurityForm: 'security_form',
  SecurityLetter: 'security_letter',
  SecurityStatistic: 'security_statistic',
  MinistryOfInterior: 'ministry_of_interior',
  InitialPolicy: 'initial_policy',
  License: 'license',
  BankAccountPermit: 'bank_account_permit',
  PermitEstablishBoard: 'permit_to_establish_board',
  Policy: 'policy',
  EstablishmentDecision: 'establishment_decision',
  Question: 'question_file',
  BoardOfTrustees: 'board_of_trustees_minutes_of_meetings',
  unifiedNationalNumber: 'unified_national_number',
};

export const LoginSteps = {
  Verification: 'Verification',
  Confirmation: 'Confirmation',
};

export const FileErrorTypes = {
  Size: 'size',
  Extension: 'extension',
  Request: 'request',
};

export const EntityTypes = {
  CooperativeAssociation: 'cooperativeAssociation',
  PrivateAssociation: 'privateAssociation',
  PrivateCorporation: 'privateCorporation',
  FamilyFund: 'familyFund',
};

export const RequestStatuses = {
  Draft: 'draft',
  MemberApproval: 'member_approval',
  MemberAdjustments: 'member_adjustments',
  SupervisoryApproval: 'supervisory_approval',
  SupervisoryAdjustments: 'supervisory_adjustments',
  RegistrationAndPermitApproval: 'registration_and_permit_admin_approval',
  RegistrationAndPermitEmployee: 'registration_and_permit_employee_approval',
  AcceptedBySpecialized: 'accepted_by_specialized_admin',
  AcceptedByHrsdEmployee: 'accepted_by_hrsd_employee',
  AcceptedByGovernanceAdmin: 'accepted_by_governance_admin',
  AcceptedByUser: 'accepted_by_user',
  FoundersApproval: 'founders_approval',
  Accepted: 'accepted',
  Rejected: 'rejected',
  Inquiry: 'inquiry',
  AdminEdit: 'admin_edit',
  RequestResults: 'request_results',
  SpecializedApproval: 'pending',
  Adjustment: 'adjustment',
  Pending: 'pending',
  PendingAfterAdjustment: 'pending_after_adjustment',
  AutoAccepted: 'autoaccepted',
  Canceled: 'canceled',
};

export const MemberTypes = {
  Individual: 'Individual',
  Existing: 'Existing',
  Delegate: 'Delegate',
};

export const SpecializedStatuses = {
  Accepted: 'accepted',
  Rejected: 'rejected',
  Pending: 'pending',
};

export const ClassificationsTypes = {
  MainClassification: 'MainClassification',
  FirstSubClassification: 'FirstSubClassification',
  SecondSubClassification: 'SecondSubClassification',
};

export const EstablishmentType = {
  Entity: 'Entity',
  Branch: 'Branch',
  FamilyFund: 'familyFund',
};

export const mediaAttachableTypes = {
  Branch: 'Branch',
  Entity: 'Entity',
  GovernmentalEntity: 'GovernmentalEntity',
  User: 'User',
  Membership: 'Membership',
  EmployeeRequest: 'EmployeeRequest',
  DelegatorProof: 'DelegatorProof',
  WebsiteLicense: 'WebsiteLicense',
};

export const mediaDocTypes = {
  ApprovalFile: 'approval_file',
  BankCertificate: 'bank_certificate',
  BankVerificationFile: 'bank_verification_file',
  Certificate: 'certificate',
  EligibilityStudyFile: 'eligibility_study_file',
  GovernmentalFile: 'governmental_file',
  EntityAcknowledgmentFile: 'entity_acknowledgment_file',
  ProofFile: 'proof_file',
  SpecializedAdminFile: 'specialized_admin_file',
  MinistryOfInteriorAdminFile: 'ministry_of_interior_admin_file',
  ReferenceTemplates: 'reference_templates',
  GeneralAssemblyFile: 'general_assembly_file',
  TempBankStatement: 'temp_bank_account_statement_file',
  TempBankCertificate: 'temp_bank_account_certificate_file',
  ConfirmationOfClose: 'temp_bank_account_confirmation_of_close_file',
  DelegatorProofLetter: 'delegator_proof_letter',
  NetworkCertificate: 'saudi_network_registration_certificate',
  ApprovementWebsite: 'approving_establishment_website',
  ExtraordinaryMeetingFile: 'pc_general_assembly_file',
  Question: 'question_file',
};

export const LocaleTypes = {
  En: 'en',
  Ar: 'ar',
};

export const EditRequestTypes = {
  regulationsAndGeografic: 'regulation_items_and_geografic_information',
  primaryInformation: 'primary_information',
  goalsAndActivities: 'goals_and_activities',
  entityEstablishmentSupervisoryShanges: 'entity_establishment_supervisory_changes',
  familyFundEmployeeCenterAdmin: 'ffe_establishment_rp_employee_changes',
  familyFundCenterAdmin: 'ffe_establishment_rp_admin_changes',
  familyFundCeoAdmin: 'ffe_establishment_rp_ceo_changes',
};

export const EditRequestTypesByAdminRole = {
  registration_and_permit_admin: 'ffe_establishment_rp_admin_changes',
  registration_and_permit_employee: 'ffe_establishment_rp_employee_changes',
  registration_and_permit_ceo: 'ffe_establishment_rp_ceo_changes',
};

export const EditRequestFieldActions = {
  Update: 'update',
  Create: 'create',
  Delete: 'delete',
  NotUpdated: 'notUpdated',
};

export const EditRequestUnitTypes = {
  Entity: 'Entity',
  EntityLocation: 'EntityLocation',
  EntityNationalAddress: 'EntityNationalAddress',
  FamilyFundSubscription: 'FamilyFundSubscription',
  EntitiesBank: 'EntitiesBank',
};

export const RequestTypes = {
  EntityPolicy: 'entityPolicy',
};

export const EstablishmentRequestStatuses = {
  Pending: 'pending',
  Adjustment: 'adjustment',
  Accepted: 'Accepted',
  Rejected: 'Rejected',
};

export const EstablishmentRequestType = {
  Approval: 'approval',
  Recommendation: 'recommendation',
};

export const ActivityRequestApprovalStatuses = {
  Draft: 'draft',
  SupervisoryApproval: 'supervisory_approval',
  GovernanceAdminApproval: 'governance_admin_approval',
  HrsdEmployeeApproval: 'hrsd_employee_approval',
  GovernanceCenterEmployee: 'governance_center_employee',
  GovernanceEmployeeRecommendation: 'governance_employee_recommendation',
  Accepted: 'accepted',
  Rejected: 'rejected',
};

export const DatePattern = {
  General: 'dd/MM/yyyy',
  Rtl: 'yyyy/MM/dd',
  Full: 'dd/MM/yyyy hh:mm',
};

export const TimePart = {
  Seconds: 'seconds',
  Minutes: 'minutes',
  Hours: 'hours',
};

export const ReservationRoutes = {
  CreateActivityRequests: 'entity_activities',
  EstablishmentRequests: 'establishment_admin_requests',
  EditingPolicyRequests: 'edit_requests',
  EmployeeRequests: 'employee_requests',
};

export const ReservationRoutesV2 = {
  CreateActivityRequests: 'EntityActivity',
  EstablishmentRequests: 'EstablishmentAdminRequest',
  EditingPolicyRequests: 'EditRequest',
  EmployeeRequests: 'EmployeeRequest',
};

export const EditRequestUnitActions = {
  Create: 'create',
  Update: 'update',
  Delete: 'delete',
  Idle: 'idle',
};

export const MembershipEntityTypes = {
  Entity: 'Entity',
  Branch: 'Branch',
};

export const EmployeePositions = {
  Ceo: 'ceo',
  Accountant: 'accountant',
  Other: 'other',
};

export const NonGovernmentalEntityTypes = {
  NonProfitOrganizationLicense: 'NonProfitOrganizationLicense',
  CommercialRegistrationNumber: 'CommercialRegistrationNumber',
  EndowmentDeedNumber: 'EndowmentDeedNumber',
};

export const MembershipTypeAssociation = {
  Closed: 'closed',
  Open: 'open',
};

export const TypeOfBenefit = {
  Closed: 'closed',
  Open: 'open',
};

export const KindOfExternal = {
  Financial: 'financial',
  Logistics: 'logistics',
  Assets: 'assets',
};

export const LicenseRequestRecommendations = {
  NotRecommended: 'not_recommended',
  Recommended: 'recommended',
};

export const RemainingType = {
  Day: 'd',
  Min: 'min',
};

export const NamaAdminRequestStatus = {
  Pending: 'pending',
  Accepted: 'accepted',
  Rejected: 'rejected',
};

export const EditPolicyRequestType = {
  NewRequests: 'NewRequests',
  RequestsHistory: 'RequestsHistory',
};

export const UpdatingEntitiesRequestType = {
  NewRequests: 'UpdatingEntitiesNewRequests',
  RequestsHistory: 'UpdatingEntitiesRequestsHistory',
};

export const EshopRequestType = {
  NewRequests: 'EshopNewRequests',
  RequestsHistory: 'EshopRequestsHistory',
};

export const EmployeeRequestType = {
  NewRequests: 'EmployeeNewRequests',
  RequestsHistory: 'EmployeeRequestsHistory',
};

export const TemporaryBankAccountRequestType = {
  NewRequests: 'TemporaryBankAccountNewRequests',
  RequestsHistory: 'TemporaryBankAccountRequestsHistory',
};

export const ActivityRequestType = {
  NewRequests: 'ReceivedActivitiesNewRequests',
  RequestsHistory: 'ReceivedActivitiesRequestsHistory',
};

export const MembershipTypesOptions = {
  Worker: 'worker',
  Associate: 'associate',
  Honorary1: 'honorary1',
  Honorary2: 'honorary2',
};

export const featureNames = {
  filtersForBranchRequests: 'FEATURE_FILTERS_FOR_BRANCH_REQUESTS',
  filtersForEditPolicyRequests: 'FEATURE_FILTERS_FOR_EDIT_POLICY_REQUESTS',
  hotkeys: 'FEATURE_HOTKEYS',
  generateEntitiesListDoc: 'FEATURE_GENERATE_ENTITIES_LIST_DOC',
  manageMeetings: 'FEATURE_MANAGE_MEETINGS',
  notificationsPage: 'FEATURE_NOTIFICATIONS_PAGE',
  questionSupervisoryFlow: 'FEATURE_QUESTION_SUPERVISORY_FLOW',
  branchQuestionFlow: 'FEATURE_BRANCH_QUESTION_FLOW',
  referencesRoute: 'FEATURE_REFERENCES_ROUTE',
  unifiedNumberFilter: 'FEATURE_UNIFIED_NUMBER_FILTER',
  branchRemainingTime: 'FEATURE_BRANCH_REMAINING_TIME',
};

export const EntityCardsTypes = {
  EntityMember: 'entityMember',
  EntityOwner: 'entityOwner',
  BranchOwner: 'branchOwner',
};

export const EditPolicyStatuses = {
  Draft: 'draft',
  Pending: 'pending',
  Rejected: 'rejected',
  Accepted: 'accepted',
};

export const UnitTypes = {
  Entity: 'Entity',
  Branch: 'Branch',
};

export const MemberRole = {
  Owner: 'Owner',
  Individual: 'Individual',
  Delegate: 'Delegate',
  Guest: 'Guest',
};

export const PublicDocType = {
  License: 'License',
};

export const PublicItemType = {
  Entity: 'Entity',
};

export const ResetPasswordSteps = {
  Email: 'EmailStep',
  ConfirmOtp: 'ConfirmOtpStep',
  NewPassword: 'NewPasswordStep',
};

export const ActivityRequestsRoutes = {
  Requests: 'NewEntityActivityRequests',
  RequestsHistory: 'EntityActivityRequestsHistory',
  AcceptedRequests: 'EntityActivityAcceptedRequests',
};

export const BankDelegationAdminRequestStatus = {
  Accepted: 'accepted',
  Rejected: 'rejected',
  Pending: 'pending',
};

export const TranslationsForTitles = {
  Ar: 'arTitle',
  En: 'enTitle',
};

export const FamilyFundTypes = {
  Self: 'self',
  Affiliated: 'affiliated',
  General: 'general',
};
